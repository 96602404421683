import React from 'react'
import androidPhone from '../../assets/images/screenshots/android_phone_vertical_metronome.png'
import gPlayBadge from '../../assets/images/home/google_play_badge.png'
import { Link } from 'react-router-dom'

const TopBlock: React.FC = () => {
  return (
    <div className="top-block row">
      <div className="col m6 description">
        <p>A smart metronome for drummers and all other musicians.</p>
        <div className="badges">
          <div className="badge">
            <a
              href="https://play.google.com/store/apps/details?id=com.damienvauchel.smartronom_free"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={gPlayBadge} alt="google play badge" />
            </a>
          </div>
          <div className="paypalDonate">
            <Link to="/donate">
              <input
                type="image"
                src="https://www.paypalobjects.com/en_US/FR/i/btn/btn_donateCC_LG.gif"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
                alt="Donate with PayPal button"
              />
              <img
                alt=""
                src="https://www.paypal.com/en_FR/i/scr/pixel.gif"
                width="1"
                height="1"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="col m6 previews">
        <div className="row">
          <img
            src={androidPhone}
            alt="android smartphone preview"
            className="android-phone-preview"
          />
        </div>
      </div>
    </div>
  )
}

export default TopBlock
