import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MetronomeBlock from '../components/AboutPage/MetronomeBlock'
import SheetsBlock from '../components/AboutPage/SheetsBlock'
import SetListsBlock from '../components/AboutPage/SetListsBlock'
import GeneralBlock from '../components/AboutPage/GeneralBlock'

const AboutPage: React.FC = () => {
  return (
    <div id="about">
      <Header />
      <div className="content">
        <GeneralBlock />
        <MetronomeBlock />
        <SheetsBlock />
        <SetListsBlock />
      </div>
      <Footer />
    </div>
  )
}

export default AboutPage
