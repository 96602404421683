import React from 'react'
import { Link, withRouter } from 'react-router-dom'

interface Props {
  className: string
}

const Navbar: React.FC<Props & any> = ({ className }) => {
  return (
    <nav className={className}>
      <ul>
        <li>
          <Link to="/">Smartron'home</Link>
        </li>
        <li>
          <Link to="/about">What is it?</Link>
        </li>
        <li>
          <Link to="/team">Team</Link>
        </li>
      </ul>
    </nav>
  )
}

export default withRouter(Navbar)
