import React from 'react'
import Header from '../components/Header'
import TopBlock from '../components/HomePage/TopBlock'
import Footer from '../components/Footer'

const HomePage: React.FC = () => {
  return (
    <div id="home">
      <Header />
      <TopBlock />
      <Footer />
    </div>
  )
}

export default HomePage
