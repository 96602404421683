import React from 'react'

const MarineBlock: React.FC = () => {
  return (
    <div className="right-block block marine-block">
      <div className="description">
        <div className="pic" />
        <div className="desc">
          <h2>Marine</h2>
          <ul>
            <li>Pro bass player</li>
            <li>Bass teacher</li>
            <li>Composer</li>
            <li>Validation team</li>
            <li>Left hander pastas maker</li>
          </ul>
          <div className="social-medias">
            <a
              href="https://www.facebook.com/MarineCourtinBassPlayer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="https://www.instagram.com/marine_courtin_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCp43kVN2cEjNi--8Du9h2-w"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MarineBlock
