import React from 'react'
import androidPhone from '../../assets/images/screenshots/android_phone_vertical_metronome.png'

const MetronomeBlock: React.FC = () => {
  return (
    <div className="metronome-block block">
      <div className="description">
        <p>A metronome.</p>
        <img
          src={androidPhone}
          alt="android smartphone preview"
          className="android-phone-preview"
        />
      </div>
    </div>
  )
}

export default MetronomeBlock
