import React from 'react'
import androidPhone1 from '../../assets/images/screenshots/android_phone_vertical_setlistlist.png'
import androidPhone2 from '../../assets/images/screenshots/android_phone_vertical_singlesetlist.png'

const SetListsBlock: React.FC = () => {
  return (
    <div className="setlists-block block row">
      <div className="col m6 description">
        <p>
          A set lists management for shows organisation. Easily create a new Set
          list and add some created sheets. You can easily edit for last minute
          changes. <br />
          <br />
          This feature allows you to play and view all sheets in orders with
          automatic tempo changes.
        </p>
        <img
          src={androidPhone1}
          alt="android smartphone preview"
          className="android-phone-preview"
        />

        <img
          src={androidPhone2}
          alt="android smartphone preview"
          className="android-phone-preview mobile-hidden"
        />
      </div>
    </div>
  )
}

export default SetListsBlock
