import React, { useState, useEffect } from 'react'
import logo from '../assets/images/light_logo_name.png'
import Navbar from './Navbar'

const Header: React.FC = () => {
  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 100
      if (scrolled !== show) {
        setScrolled(show)
      }
    }
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled])

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <header className={`header row ${scrolled === true ? 'scrolled' : ''}`}>
      <div className="col l2 m2 s2">
        <div
          className={`header-logo right ${scrolled === true ? 'small' : ''}`}
          onClick={handleLogoClick}
        >
          <img src={logo} alt="smartronom logo" />
        </div>
      </div>
      <Navbar
        className={`nav col l6 offset-l4 m8 offset-m2 s9 offset-s1 ${
          scrolled === true ? 'hidden' : ''
        }`}
      />
    </header>
  )
}

export default Header
