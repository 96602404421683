import React from 'react'

const GeneralBlock: React.FC = () => {
  return (
    <div className="general-block block">
      <div className="description">
        <p>
          Smartronom is an easy to use application for musicians. It has been
          developped especially for drummers' needs but every musician will find
          it useful.
        </p>
        <p>For now, available on Android smartphones and tablets.</p>
        <p>It has 3 major features :</p>
      </div>
    </div>
  )
}

export default GeneralBlock
