import React from 'react'
import androidPhone1 from '../../assets/images/screenshots/android_phone_vertical_sheetlist.png'
import androidPhone2 from '../../assets/images/screenshots/android_phone_vertical_singlesheet.png'

const SheetsBlock: React.FC = () => {
  return (
    <div className="sheets-block block row">
      <div className="col m6 description">
        <img
          src={androidPhone1}
          alt="android smartphone preview"
          className="android-phone-preview"
        />

        <img
          src={androidPhone2}
          alt="android smartphone preview"
          className="android-phone-preview mobile-hidden"
        />
        <p>
          A sheets management. Associate a sheet with a tempo to automatically
          launch a set metronome when playing the sheet viewer.
          <br />
          <br />
          You can directly scan a sheet with the phone camera or you can import
          one from your gallery.
          <br />
          <br />
          Easy to manage and edit.
        </p>
      </div>
    </div>
  )
}

export default SheetsBlock
