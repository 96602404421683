import React from 'react'
import gPlayBadge from '../assets/images/home/google_play_badge.png'

const Footer: React.FC = () => {
  return (
    <header className="footer">
      <div className="">
        Copyright © 2020{' '}
        <a
          href="https://damienvauchel.fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          Damien Vauchel
        </a>
      </div>
      <div className="badges">
        <a
          href="https://play.google.com/store/apps/details?id=com.damienvauchel.smartronom_free"
          target="_blank"
          rel="noopener noreferrer"
          className="googlePlay"
        >
          <img src={gPlayBadge} alt="google play badge" />
        </a>
        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top"
          className="paypalDonate"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="DD86ZA4E7YXJ4" />
          <input
            type="image"
            src="https://www.paypalobjects.com/en_US/FR/i/btn/btn_donateCC_LG.gif"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
          <img
            alt=""
            src="https://www.paypal.com/en_FR/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </div>
      <div className="social-medias">
        <a
          href="https://twitter.com/smartronom"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter" />
        </a>
        <a
          href="https://www.facebook.com/smartronom"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook-f" />
        </a>
        <a
          href="https://www.instagram.com/smartronom/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram" />
        </a>
        <a
          href="https://www.linkedin.com/company/smartronom"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-linkedin-in" />
        </a>
      </div>
    </header>
  )
}

export default Footer
