import React from 'react'

const DamienBlock: React.FC = () => {
  return (
    <div className="right-block block damien-block">
      <div className="description">
        <div className="pic" />
        <div className="desc">
          <h2>Damien</h2>
          <ul>
            <li>Mobile developper</li>
            <li>Web developper</li>
            <li>Logo designer</li>
            <li>Web designer</li>
            <li>Piano player</li>
            <li>Comic books reader</li>
          </ul>
          <div className="social-medias">
            <a
              href="https://damienvauchel.fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-globe" />
            </a>
            <a
              href="https://twitter.com/Scooby_Dam"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DamienBlock
