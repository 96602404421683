import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const ThankYouPage: React.FC = () => {
  return (
    <div id="team">
      <Header />
      <div className="content donate">
        <p>
          Thank you very much for your support! You can contact us if you have
          some ideas, needs or feedbacks to give to{' '}
          <a href="mailto:smartronom@gmail.com">smartronom@gmail.com</a>.
        </p>
      </div>
      <Footer />
    </div>
  )
}

export default ThankYouPage
