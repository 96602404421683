import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

const DonatePage: React.FC = () => {
  return (
    <div id="team">
      <Header />
      <div className="content donate">
        <p>
          To let the application free and ad-free and make it evolve for more
          features, you can do a donation on every amount you want. Via Paypal
          or credit card. Thank you for your support!
        </p>
        <form
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top"
          className="paypalDonate"
        >
          <input type="hidden" name="cmd" value="_s-xclick" />
          <input type="hidden" name="hosted_button_id" value="DD86ZA4E7YXJ4" />
          <input
            type="image"
            src="https://www.paypalobjects.com/en_US/FR/i/btn/btn_donateCC_LG.gif"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
          <img
            alt=""
            src="https://www.paypal.com/en_FR/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </div>
      <Footer />
    </div>
  )
}

export default DonatePage
