import React from 'react'
import 'materialize-css/dist/css/materialize.min.css'
import './assets/scss/App.scss'
import HomePage from './pages/HomePage'

const App: React.FC = () => {
  return (
    <div className="App">
      <HomePage />
    </div>
  )
}

export default App
