import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import App from './App'
import AboutPage from './pages/AboutPage'
import TeamPage from './pages/TeamPage'
import PrivacyPage from './pages/PrivacyPage'
import DonatePage from './pages/DonatePage/DonatePage'
import ThankYouPage from './pages/DonatePage/ThankYouPage'

const router: any = () => {
  return (
    <Router>
      <div>
        <Route exact path="/" component={App} />
        <Route exact path="/about" component={AboutPage} />
        <Route exact path="/team" component={TeamPage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/donate" component={DonatePage} />
        <Route exact path="/thankyou" component={ThankYouPage} />
      </div>
    </Router>
  )
}

export default router
