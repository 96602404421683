import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import DamienBlock from '../components/TeamPage/DamienBlock'
import ThomasBlock from '../components/TeamPage/ThomasBlock'
import MarineBlock from '../components/TeamPage/MarineBlock'

const TeamPage: React.FC = () => {
  return (
    <div id="team">
      <Header />
      <div className="content">
        <DamienBlock />
        <ThomasBlock />
        <MarineBlock />
      </div>
      <Footer />
    </div>
  )
}

export default TeamPage
