import React from 'react'

const ThomasBlock: React.FC = () => {
  return (
    <div className="left-block block thomas-block">
      <div className="description">
        <div className="desc">
          <h2>Thomas</h2>
          <ul>
            <li>Pro drums player</li>
            <li>Drums teacher</li>
            <li>Composer</li>
            <li>Needs teller and ideas provider</li>
            <li>Validation team</li>
            <li>Tattooed man</li>
          </ul>
          <div className="social-medias">
            <a
              href="https://www.facebook.com/tom.desmet.319/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a
              href="https://www.instagram.com/tom__desmet/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram" />
            </a>
          </div>
        </div>
        <div className="pic" />
      </div>
    </div>
  )
}

export default ThomasBlock
